import React from 'react';
import ReactDOM from 'react-dom';

import DomainModules from './components/DomainModules';
import './domain-modules.scss';

ReactDOM.render(
  <DomainModules />,
  document.querySelector('#domain-modules')
);

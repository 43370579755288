import React from 'react';

import CachedAutocomplete from 'common/components/CachedAutocomplete/index';

class ModuleAutocomplete extends React.Component {
  render() {
    const {
      availableModules,
      addModules
    } = this.props;

    const moduleSearchProps = {
      dataCached: availableModules,
      onQuerySubmit: addModules,
      placeholder: 'Find a Module',
      className: 'find-a-module',
      searchKeys: ['name', 'description'],
      onSelectSetSelectionAsQuery: false,
      focusFirstResult: true
    };

    return <CachedAutocomplete {...moduleSearchProps} />;
  }
}

export default ModuleAutocomplete;

// This file stores configuration for modules that require action to be taken when they are removed or disabled
// This file also organizes the actions that should be taken after a module is removed or disabled.

import FederationsApi from 'common/core/federations';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';

export const modulesToShowConfirmDialog = ['federations'];
export const moduleConfig =
{
  'federations': {
    moduleMessage: 'Disabling or removing this module will terminate all existing federations on the domain. This may break assets across multiple domains. Are you sure you want to continue?',
    toastActionFailureMessage: 'Error deleting federation relationships: see console',
    actionFailureMessage: 'There was a problem removing federation relationships. Please manually delete federations from /admin/federations now that the federations module has been removed or deleted.'
  }
};

// Do things once "Confirm" is clicked on the remove module dialog depending on the module
export const removeModuleAction = async (currentModule) => {
  switch (currentModule) {
    case 'federations':
      try {
        await FederationsApi.deleteAllFederationsForDomain();
      } catch (error) {
        showErrorToastNow(moduleConfig.federations.toastActionFailureMessage);
        console.log(moduleConfig.federations.actionFailureMessage);
        console.log('Error was: ', error);
      }
      break;
  }
};

// Do things once "Confirm" is clicked on the disable dialog depending on the module
export const disableModuleAction = async (currentModule) => {
  switch (currentModule) {
    case 'federations':
      try {
        await FederationsApi.deleteAllFederationsForDomain();
      } catch (error) {
        showErrorToastNow(moduleConfig.federations.toastActionFailureMessage);
        console.log(moduleConfig.federations.actionFailureMessage);
        console.log('Error was: ', error);
      }
      break;
  }
};

import React from 'react';
import sortBy from 'lodash/sortBy';

import * as frontendApi from 'common/frontend/internal';
import * as configApi from 'common/core/configurations';
import * as domainsApi from 'common/core/domains';
import { getAllGates } from 'common/core/config_gates/provisions';
import { getCurrentUser } from 'common/current_user';
import { showSuccessToastNow, showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import Button from 'common/components/Button';
import GateInfoModal from 'common/components/GateInfoModal';
import { Modal, ModalContent, ModalFooter } from 'common/components/Modal';

import FeatureList from './FeatureList';
import ModuleAutocomplete from './ModuleAutocomplete';
import { modulesToShowConfirmDialog, moduleConfig, removeModuleAction } from '../ModuleConfigConstants';

class DomainModules extends React.Component {
  state = {
    allModules: [],
    gates: [],
    domainModules: [],
    featureSetConfig: {},
    modalHidden: true,
    modalProps: {},
    dialogVisible: false,
    currentModule: '',
    currentModuleMessage: ''
  };

  // not always the same as getCurrentDomain, so is loaded in view internal/show_domain.html.erb
  getTargetDomain = () => window.socrata.targetCname;

  showModal = (modalProps) => this.setState({ modalHidden: false, modalProps });
  hideModal = () => this.setState({ modalHidden: true });
  showDialog = () => {
    this.setState({ dialogVisible: true });
  };

  componentDidMount() {
    this.fetchFeatureSetConfig();
    this.fetchAllModules();
    this.fetchModuleGates();
  }

  handleDialogDismiss = () => {
    this.setState({ dialogVisible: false });
  };

  fetchAllModules = async () => {
    try {
      const allModules = await frontendApi.moduleList();
      this.setState({ allModules });
    } catch (error) {
      showErrorToastNow('Error fetching modules!');
      console.error(`fetchAllModules error: ${error}`);
    }
  };

  fetchFeatureSetConfig = async () => {
    try {
      const featureSetConfig = await configApi.fetchFeatureSetConfig(this.getTargetDomain());
      this.setState(handleFeatureSetConfig(featureSetConfig));
    } catch (error) {
      showErrorToastNow('Error fetching domain configuration!');
      console.error(`fetchFeatureSetConfig error: ${error}`);
    }
  };

  fetchModuleGates = async () => {
    try {
      const allGates = await getAllGates();
      const gates = allGates.filter((gate) => gate.provisionLookup.startsWith('module:'));
      this.setState({ gates });
    } catch (error) {
      showErrorToastNow('Error fetching Gates!');
      console.error(`fetchModuleGates error: ${error}`);
    }
  };

  addModules = async (modulesToAdd) => {
    const { gates } = this.state;

    const module = modulesToAdd[0]; // should only ever have 1, due to focusFirstResult

    await new Promise((resolve) => setTimeout(resolve, 200)); // prevents error from LastPass extension :(
    const matchingGate = gates.find((gate) => gate.provisionLookup.split(':')[1] === module.name);
    if (matchingGate) {
      this.showAddGateModal(module, matchingGate);
    } else {
      this.addModule(module);
    }
  };

  showAddGateModal = (module, matchingGate) => {
    const currentUser = getCurrentUser();
    const currentUserCanAdd =
      !matchingGate.allowedUsers ||
      matchingGate.allowedUsers.some((allowedUser) => currentUser.id === allowedUser.id);

    const addModuleOnClick = () => {
      this.addModule(module);
      this.hideModal();
    };

    const AddModuleButton = () => (
      <Button variant="primary" onClick={addModuleOnClick}>
        Add Module
      </Button>
    );

    const modalProps = {
      allowedUsers: matchingGate.allowedUsers,
      reason: matchingGate.reason,
      created: matchingGate.created,
      onDismiss: this.hideModal,
      millisecondsBeforeSearch: 500,
      ConfirmButton: () => currentUserCanAdd && <AddModuleButton />
    };

    this.showModal(modalProps);
  };

  addModule = async (module) => {
    const { featureSetConfig } = this.state;
    const domain = this.getTargetDomain();
    try {
      await domainsApi.addAccountModule(domain, module.name);
      const response = await configApi.addConfigProperty(featureSetConfig.id, module.name);
      showSuccessToastNow(`Successfully added module '${response.name}'`);
      await frontendApi.flushCache(domain);
    } catch (err) {
      showErrorToastNow('Error adding module!');
      console.error('addModules err', err);
    } finally {
      await this.fetchFeatureSetConfig(); // refresh config
    }
  };

  removeModule = async (moduleName) => {
    this.setState({ currentModule: moduleName });

    if (!modulesToShowConfirmDialog.includes(moduleName)) {
      this.doRemove();
    } else {
      this.setState({ currentModuleMessage: moduleConfig[moduleName].moduleMessage });
      this.showDialog();
    }
  };

  doRemove = async () => {
    const { featureSetConfig } = this.state;
    const domain = this.getTargetDomain();

    try {
      await configApi.deleteConfigProperty(featureSetConfig.id, this.state.currentModule);
      showSuccessToastNow(`Successfully removed module '${this.state.currentModule}'`);
      await frontendApi.flushCache(domain);
    } catch (err) {
      showErrorToastNow('Error removing module!');
      console.error('removeModule err', err);
    } finally {
      await this.fetchFeatureSetConfig(); // refresh config

      // If this is a module configured to have a "Confirm?"" dialog shown, run any required
      // functions and then dismiss the dialog now that the module is removed
      if (modulesToShowConfirmDialog.includes(this.state.currentModule)) {
        await removeModuleAction(this.state.currentModule);
        this.handleDialogDismiss();
      }
    }
  };

  render() {
    const { allModules, domainModules, gates, featureSetConfig, modalHidden, modalProps, dialogVisible } =
      this.state;

    const domainModuleNames = domainModules.map((module) => module.name);
    const availableModules = allModules.filter((module) => !domainModuleNames.includes(module.name));

    return (
      <section>
        {modalHidden || <GateInfoModal {...modalProps} />}
        <FeatureList
          gates={gates}
          allModules={allModules}
          domainModules={domainModules}
          featureSetConfig={featureSetConfig}
          removeModule={this.removeModule}
        />

        <h4>Add Module</h4>
        <ModuleAutocomplete availableModules={availableModules} addModules={this.addModules} />
        {dialogVisible &&
          <Modal onDismiss={this.handleDialogDismiss}>
            <ModalContent>
              {this.state.currentModuleMessage}
            </ModalContent>
            <ModalFooter>
              <Button
                type="submit"
                onClick={this.handleDialogDismiss}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={this.doRemove}
              >
                Continue
              </Button>
            </ModalFooter>
          </Modal>
        }
      </section>
    );
  }
}

// Helper
const handleFeatureSetConfig = (featureSetConfig) => {
  const domainModules = (featureSetConfig.properties || []).map((property) => ({
    name: property.name,
    enabled: property.value
  }));

  return { featureSetConfig, domainModules: sortBy(domainModules, ['name']) };
};
export default DomainModules;

import React from 'react';
import flow from 'lodash/flow';

import ModuleRow from './ModuleRow';

class FeatureList extends React.Component {
  addDescription = (allModules) => (module) => {
    const modWithDescription = allModules.find((mod) => mod.name === module.name);

    return {
      ...module,
      description: modWithDescription && modWithDescription.description
    };
  };

  addGate = (gates) => (module) => {
    const matchingGate = gates.find((gate) => gate.provisionLookup.includes(module.name));

    return {
      ...module,
      gate: matchingGate
    };
  };

  render() {
    const {
      domainModules,
      allModules,
      gates,
      removeModule,
      featureSetConfig
    } = this.props;

    const modules = domainModules.map(
      flow(this.addDescription(allModules), this.addGate(gates), (augmentedModule) => (
          <ModuleRow
            name={augmentedModule.name}
            description={augmentedModule.description}
            enabled={augmentedModule.enabled}
            gate={augmentedModule.gate}
            removeModule={removeModule}
            featureSetConfigId={featureSetConfig.id}
            key={augmentedModule.name} />
        )
      )
    );

    return <ul className="feature-list">{modules}</ul>;
  }
}

export default FeatureList;
